<template>
  <div class="hello m-5">
    <div class="text-end">
      <router-link
        style="text-decoration: none"
        class="text-white btn btn-primary m-2 flex-start-end"
        aria-current="page"
        to="/admin/add/user"
        >Add User</router-link
      >
    </div>
    <vue-good-table
      :columns="columns"
      :rows="usersList"
      :fixed-header="true"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'id', type: 'desc' },
      }"
      :search-options="{ enabled: true, placeholder: 'Search ' }"
      :pagination-options="{
        enabled: true,
        dropdownAllowAll: false,
        perPageDropdown: [10, 20, 30, 40, 50],
        perPage: 10,
        mode: 'pages',
      }"
    >
      <template slot="table-row" slot-scope="props">
        {{
          props.column.field != "account_status" &&
          props.column.field != "plan_name"
            ? props.formattedRow[props.column.field]
            : ""
        }}
        <span v-if="props.column.field == 'plan_name'">
          <span
            v-if="props.formattedRow.plan_name == 'Admin Subscriber'"
            class="badge bg-info"
          >
            {{ props.formattedRow.plan_name }}
          </span>
          <span
            v-if="props.formattedRow.plan_name == 'Monthly'"
            class="badge bg-success"
          >
            {{ props.formattedRow.plan_name }}
          </span>
          <span
            v-if="props.formattedRow.plan_name == 'Cancelled'"
            class="badge bg-danger"
          >
            {{ props.formattedRow.plan_name }}
          </span>
          <span
            v-if="props.formattedRow.plan_name == 'Cancelation Scheduled'"
            class="badge bg-info"
          >
            Auto cancel on {{ props.row.cancel_at | moment("MM-DD-YYYY") }}
          </span>
          <span
            v-if="props.formattedRow.plan_name == 'Not Started'"
            class="badge bg-secondary"
          >
            {{ props.formattedRow.plan_name }}
          </span>
          <span
            v-if="props.formattedRow.plan_name == 'Trial'"
            class="badge bg-primary"
          >
            {{ props.formattedRow.plan_name }}
          </span>
        </span>
        <span v-if="props.column.field == 'account_status'">
          <span
            class="badge bg-success"
            v-if="props.formattedRow.account_status == 1"
            >Enabled</span
          >
          <span
            class="badge bg-secondary"
            v-if="props.formattedRow.account_status == 0"
            >Disabled</span
          >
        </span>
        <span v-if="props.column.field == 'actions'">
          <button
            class="btn btn-success m-1"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal1"
            @click="edit(props.formattedRow.id)"
          >
            <i class="bi bi-pencil"></i>
          </button>
          <button
            class="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            @click="openModal(props.formattedRow.id)"
          >
            <i class="bi bi-trash-fill"></i>
          </button>
        </span>
      </template>
    </vue-good-table>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <i class="bi bi-person-x m-1"></i> Delete User
            </h5>
          </div>
          <div class="modal-body">
            <p>Are you sure, you want to delete?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="onRowClick()"
              data-bs-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit User</h5>
          </div>
          <div class="modal-body">
            <form v-if="!modalload" @submit.prevent="submitForm">
              <div class="card-block">
                <div class="row">
                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="fname">
                      First Name <span class="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': $v.formFields.first_name.$error,
                      }"
                      v-model="formFields.first_name"
                      id="fname"
                      placeholder="Enter First Name"
                    />
                  </div>

                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="lname">
                      Last Name <span class="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': $v.formFields.last_name.$error,
                      }"
                      id="lname"
                      v-model="formFields.last_name"
                      placeholder="Enter Last Name"
                    />
                  </div>
                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="email">
                      Email <span class="required">*</span>
                    </label>
                    <input
                      type="email"
                      readonly
                      class="form-control"
                      id="email"
                      v-model="formFields.email"
                      :class="{
                        'is-invalid': $v.formFields.email.$error,
                      }"
                      placeholder="rntng@gmail.com"
                      autocomplete="username"
                    />
                  </div>
                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="pno">
                      Phone <span class="required">*</span>
                    </label>
                    <input
                      type="text"
                      :class="{
                        'is-invalid': $v.formFields.phone.$error,
                      }"
                      v-model="formFields.phone"
                      class="form-control"
                      id="pno"
                      placeholder="Enter phone"
                    />
                  </div>
                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="cmp_name">
                      Company Name <span class="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': $v.formFields.company.$error }"
                      id="cmp_name"
                      v-model="formFields.company"
                      placeholder="Enter Company Name"
                    />
                  </div>
                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="cmp_website"
                      >Website <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          parseUrl == false || $v.formFields.website.$error,
                      }"
                      id="cmp_website"
                      v-model="formFields.website"
                      placeholder="Enter website"
                    />
                    <span
                      class="text-danger"
                      style="font-size: 11px"
                      v-if="parseUrl == false"
                      >Enter a valid url</span
                    >
                  </div>
                  <div class="col-sm-12 pb-3">
                    <label class="m-b-10 f-w-600" for="address">
                      Address <span class="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      v-model="formFields.address"
                      placeholder="Enter Address"
                    />
                  </div>

                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="country">
                      Country <span class="required">*</span>
                    </label>
                    <v-select
                      label="name"
                      id="country"
                      :class="{
                        'is-invalid': $v.formFields.country.$error,
                      }"
                      v-model="formFields.country"
                      placeholder="select country"
                      :options="options"
                    ></v-select>
                  </div>
                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="city">City</label>
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      v-model="formFields.city"
                      :class="{
                        'is-invalid': $v.formFields.city.$error,
                      }"
                      placeholder="Enter City"
                    />
                  </div>
                  <div class="col-sm-6 pb-3">
                    <label class="m-b-10 f-w-600" for="password"
                      >Password</label
                    >
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      v-model="fields.password"
                      placeholder="Enter Password"
                      autocomplete="current-password"
                    />
                  </div>
                  <div class="col-sm-6 mb-2">
                    <label class="m-b-10 f-w-600" for="cpassword"
                      >Confirm Password</label
                    >
                    <input
                      type="password"
                      class="form-control"
                      :class="{
                        'is-invalid': $v.fields.confirmPassword.$error,
                      }"
                      id="cpassword"
                      v-model="fields.confirmPassword"
                      placeholder="Confirm Password"
                      autocomplete="current-password"
                    />
                    <span
                      v-if="$v.fields.confirmPassword.$error"
                      style="color: red"
                      >Must be same as password</span
                    >
                  </div>
                  <p class="text-danger" style="font-size: 11px">
                    Leave password field empty, if you don't want to change
                  </p>
                  <div class="col-sm-6 mt-2 mb-2">
                    <label
                      class="form-check-label m-b-10 f-w-600"
                      for="acc_status"
                    >
                      Change Account Status
                    </label>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="status"
                        @change="onChangeEventHandler"
                        type="checkbox"
                        id="acc_status"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6 mt-2 mb-3">
                    <label
                      class="form-check-label m-b-10 f-w-600"
                      for="send_email"
                    >
                      Send Email
                    </label>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="send_email"
                        @change="onChangeEventHandler1"
                        type="checkbox"
                        id="send_email"
                      />
                    </div>
                  </div>
                  <div v-if="formFields.pay_status" class="col-sm-12 mt-2 mb-3">
                    <label
                      class="form-check-label m-b-10 f-w-600"
                      for="cancel_sub"
                    >
                      Cancel Subscription
                    </label>
                    <div class="form-check form-switch">
                      <input
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                        class="form-check-input"
                        @change="onChangeEventHandler2"
                        v-model="subs_status"
                        type="checkbox"
                        id="cancel_sub"
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                      @click="cancel()"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-primary"
                      type="submit"
                      @click="submitForm"
                      :class="loading ? 'disabled' : ''"
                    >
                      <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div v-if="modalload">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal2"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <i class="bi bi-person-x m-1"></i> Cancel Subscription
            </h5>
          </div>
          <div class="modal-body">
            <p>Are you sure, you want to Cancel Subscription?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              data-bs-dismiss="modal"
              @click="changeSubs"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              data-bs-dismiss="modal"
              @click="changeSubs1"
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  url,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import moment from "moment-timezone";
export default {
  name: "UserList",
  components: {
    vSelect,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      parseUrl: true,
      modalload: false,
      subs_status: false,
      subs_status1: false,
      status: "",
      send_email: false,
      formFields: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company: "",
        website: "",
        address: "",
        country: "",
        city: "",
        account_status: "",
        pay_status: "",
      },
      fields: {
        password: "",
        confirmPassword: "",
      },
      feeds: [],
      loading: false,
      role: "",
      val: false,
      userId: "",
      columns: [
        {
          label: "id",
          field: "id",
          sortable: true,
        },
        {
          label: "name",
          field: "first_name",
          sortable: true,
        },
        {
          label: "Email",
          field: "email",
          sortable: true,
        },
        {
          label: "Plan Name",
          field: "plan_name",
          sortable: false,
        },
        {
          label: "Status",
          field: "account_status",
          formatFn: this.formatFn,
          sortable: false,
        },
        {
          label: "Action",
          field: "actions",
          sortable: false,
          globalSearchDisabled: true,
        },

        // ...
      ],
      options: [
        { name: "Canada", code: "CA" },
        { name: "Australia", code: "AU" },
        { name: "United States", code: "US" },
        { name: "United Kingdom", code: "UK" },
        { name: "Other", code: "other" },
      ],
    };
  },
  validations: {
    formFields: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: { required },
      company: { required },
      website: { required },
      address: "",
      account_status: "",
      country: { required },
      city: { required },
      pay_status: "",
    },
    fields: {
      password: { minLength: 6 },
      confirmPassword: {
        minLength: 6,
        sameAsPassword: sameAs(function () {
          return this.fields.password;
        }),
      },
    },
  },
  computed: { ...mapGetters(["usersList", "userList"]) },
  created() {
    this.fetchUsers()
      .then((response) => {
        // console.log('userlist',response)
      })
      .catch((err) => {
        localStorage.clear();
        this.$router.push("/login");
      });
    this.feeds = JSON.parse(JSON.stringify(this.usersList));
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      "deleteUsers",
      "fetchUserById",
      "updateAccount",
      "updateUser1",
    ]),
    onChangeEventHandler1(event) {
      this.send_email = event.target.checked;
    },
    onChangeEventHandler2(event) {
      this.subs_status1 = event.target.checked;
    },
    onChangeEventHandler(event) {
      let bool;
      if (event.target.checked) {
        bool = 1;
      } else {
        bool = 0;
      }
      let obj = {
        account_status: bool,
      };
      this.formFields.account_status = bool;
    },
    formatFn: function (value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
    changeSubs() {
      this.subs_status = false;
    },
    changeSubs1() {
      this.subs_status = true;
    },
    edit(id) {
      this.$v.$reset();
      this.userId = id;
      this.modalload = true;
      this.send_email = false;
      this.fetchUserById(id)
        .then((data) => {
          this.formFields = this.userList?.user_data;
          // if(this.userList?.user_data.pay_status){
          //   this.subs_status=true
          // }else{
          //   this.subs_status=false
          // }
          if (this.userList?.user_data.account_status) {
            this.status = true;
          } else {
            this.status = false;
          }
          this.modalload = false;
        })
        .catch(() => {
          this.modalload = false;
        });
    },
    onRowClick() {
      // console.log(this.userId)
      this.deleteUsers(this.userId);
    },
    openModal(id) {
      this.userId = id;
    },
    onEditClick(id) {},
    parseLink(href) {
      var res = href.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    editUser() {
      if (this.formFields.website != "") {
        this.parseUrl = this.parseLink(this.formFields.website);

        if (this.parseUrl == false) {
          return false;
        }
      }
      this.loading = true;
      var temp = {
        first_name: this.formFields.first_name,
        last_name: this.formFields.last_name,
        email: this.formFields.email,
        company: this.formFields.company,
        phone: this.formFields.phone,
        country: this.formFields.country.name
          ? this.formFields.country.name
          : this.formFields.country,
        city: this.formFields.city,
        address: this.formFields.address,
        website: this.formFields.website,
        user_id: this.userId,
        account_status: this.formFields.account_status,
        password: this.fields.password,
        confirmPassword: this.fields.confirmPassword,
        send_email: this.send_email,
        cancel_subs: this.subs_status,
      };
      //  console.log("obj", temp);
      this.updateUser1(temp)
        .then(() => {
          (this.subs_status = false), $("#exampleModal1").modal("hide");
          this.$toasted.show(`User Successfully updated `, {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 1000,
            singleton: true,
          });
          this.loading = false;
        })
        .catch(() => {
          this.subs_status = false;
          this.$toasted.show(`Something went wrong`, {
            theme: "bubble",
            type: "Error",
            position: "top-center",
            duration: 1000,
            singleton: true,
          });
          this.loading = false;
        });
    },
    submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.editUser();
        //alert("form submitted Successfully");
      } else {
        // alert("form submission failed");
      }
    },
    cancel() {
      this.subs_status = false;
      if (this.formFields.account_status) {
        this.status = true;
      } else {
        this.status = false;
      }
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.form-switch .form-check-input {
  width: 50px !important;
  height: 2rem !important;
}
</style>
